.btn {
    display: inline-block;
    color: inherit;
    background: none;
    border: 0;
    -webkit-appearance: none;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    box-shadow: none;
    border-radius: 0;
    width: auto;
    @include textstyle--a;
    height: auto;
    line-height: $line-height;

    svg {
        display: block;
        width: $font-size--a; // full line height
        height: $font-size--a; // full line height
        transition: opacity $trans-time--s $trans-ease--default;
    }

    &.btn--s {
        @include textstyle--b;

        svg {
            width: $font-size--b; // full line height
            height: $font-size--b; // full line height
        }
    }

    i {
        font-style: inherit;
    }

    &.btn--layer-toggle {
        align-self: center;

        body:not(.has-menu) .layer--menu & {
            transform: scale(-1);
        }

        .has-categories .layer--menu & {
            svg {
                opacity: 0;
            }
        }
    }

    header & {
        &:not(:first-child) {
            margin-left: 1rem;
        }
    }

    &[disabled] {
        cursor: default;
    }
}

// make clickable headers clickable on iOS as well
[type="button"] {
    cursor: pointer;
}
