footer {
    min-height: $header-height;
    padding: ($header-height - ($font-size--a * $line-height)) / 2 $padding-h; // header height minus full text height devided by two
    display: flex;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 2;

    h1,
    h2,
    h3,
    p {
        flex: 1 1 auto;
    }

    button {
        flex: 0 0 auto;
    }

    i {
        font-style: inherit;
    }
}
