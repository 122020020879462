// default lander--passpartout
.lander {
    display: flex;
    flex-direction: column;
    padding-bottom: $header-height;
    overflow: hidden;

    header {
        flex: 0 0 auto;
        background: $back-color--header;
        color: $text-color--header;

        @include respond-to(desktop) {
            h1 {
                text-align: center;
            }

            .btn {
                width: 25%;
                margin: 0;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    // declarations for fullscreen lander
    &.lander--fullscreen {
        padding-bottom: 0;

        header {
            background: transparent;
        }
    }
}

.lander__slider {
    display: block;
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: $header-height;
    transition: opacity 1.4s linear,
                transform 1.4s $trans-ease--default;

    // dont show wrapper at first
    &.lander__slider--invisible {
        opacity: 0;
        transform: scale(0.9);
    }

    // hide if categories are shown
    .has-categories & {
        opacity: 0;
        transition: opacity $trans-time--m $trans-ease--default;
    }

    @media only screen and (orientation: portrait) {
        top: calc(#{$header-height} + 5vh);
        bottom: calc(#{$header-height} + 5vh);
    }

    // declarations for fullscreen lander
    .lander--fullscreen & {
        top: 0;
        bottom: 0;
        z-index: -1;

        // show different animation for fullscreen slider
        &.lander__slider--invisible {
            transform: scale(1.1);
        }
    }
}

.lander__slider-element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    background: $back-color--default;
    transition: opacity $trans-time--xl $trans-ease--default;
    pointer-events: none;

    &.is-active {
        opacity: 1;
        z-index: 1;
        pointer-events: all;

        img {
            transform: scale(1.05);
        }
    }

    &.is-active-out {
        opacity: 0;
        z-index: 2;

        img {
            transform: scale(1.1);
        }
    }

    .media-box {
        height: 100%;
        max-height: calc(28rem + 40vh); // only scale slightly on bigger screens
        max-width: calc(100% - #{$padding-h * 2});
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        .firefox & {
            display: flex; // fix for firefox
            justify-content: center;
            width: calc(100% - #{$padding-h * 2});
        }

        img {
            width: auto !important;
            height: 100%;
            transition: transform $trans-time--xl $trans-ease--default;
        }
    }

    // declarations for fullscreen lander
    .lander--fullscreen & {
        .media-box {
            max-height: none;
            max-width: none;

            .firefox & { // (end) fix for firefox
                width: 100%;
            }

            // have fullscreen images for landscape sources
            &.media-box--landscape {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transform: none;

                img {
                    width: calc(100% + 2px) !important;
                    height: calc(100% + 2px);
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    object-position: 50% 50%;

                    .ie & {
                        font-family: 'object-fit: cover; object-position: 50% 50%;'; // ie fix probably
                    }
                }
            }
        }
    }

    // handle portraits
    @media only screen and (orientation: portrait) {
        .media-box {
            max-height: 100vw;
            max-width: calc(100% - #{$padding-h * 4});
        }
    }

}
