@mixin spinner($delay: 1s, $size: 2.4rem) {
    width: $size;
    height: $size;
    border-radius: 50%;
    border: 0.2em solid $text-color--albums;
    opacity: 0;
    animation: spinner 0.9s infinite $delay ease-in-out;
}

.spinner {
    @include spinner(0.2s);

    .album-content--loaded & {
        animation: none !important;
        visibility: hidden;
    }
}

@include keyframes(spinner) {
    0%, 100% {
        opacity: 1;
        transform: scale(0.5);

    }
    50% {
        opacity: 1;
        transform: scale(1.0);
    }
}
