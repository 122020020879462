// the footer-elements
.album-page,
.album-info {
    cursor: pointer;
}

.album-info {
    flex: 1;

    span {
        display: none;
        font-family: $font-family--default;

        .has-album-info-opened & {
            display: inline;
        }
    }
}

.album-page {
    flex: 1;
    text-align: right;

    span:not(.album-page-close) {
        .has-album-index-opened & {
            display: none;
        }
    }
}

.album-page-i,
.album-page-n {
    padding: 0 0.125em;
}

.album-page-close {
    display: none;
    font-family: $font-family--default;

    .has-album-index-opened & {
        display: inline;
    }
}

@include respond-to(mobile) {
    [data-action="open-detail-info"] {
        .has-album-index-opened & {
            opacity: 0;
            pointer-events: none;
        }
    }

    [data-action="open-detail-index"] {
        .has-album-info-opened & {
            opacity: 0;
            pointer-events: none;
        }
    }
}

// slideshow nav // desktop only
.album-nav {
    display: flex;
    justify-content: center;
    flex: 2;
    padding-right: 33%; // show elements in the left half
    user-select: none;

    .btn {
        white-space: nowrap;
        display: flex;
        flex: 1;
        padding: 0 1rem;
        transition: opacity $trans-time--s $trans-ease--default,
                    transform $trans-time--s $trans-ease--default;

        @for $i from 0 through 3 {
            &:nth-child(#{$i}) {
                transition-delay: 0.02s * $i;
            }
        }

        .has-album-info-opened &,
        .has-album-index-opened & {
            opacity: 0;
            pointer-events: none;
        }

        .has-album-info-opened & {
            transform: translate3d(2rem, 0, 0);
        }

        .has-album-index-opened & {
            transform: translate3d(-2rem, 0, 0);
        }
    }

    .album-nav-btn-prev-album,
    .album-nav-btn-prev {
        justify-content: flex-end;

        .album--first-element & {
            //visibility: hidden;
        }
    }

    .album-nav-btn-next-album,
    .album-nav-btn-next {
        //display: flex;

        .album--last-element & {
            display: none;
        }
    }

    .album-nav-btn-prev{
        //display: flex;

        .album--first-element & {
            display: none;
        }
    }

    .album-nav-btn-prev-album,
    .album-nav-btn-next-album {
        display: none;
        span {
            span {
                display: none;
            }
        }
    }


    .album-nav-btn-prev-album,
    .album-nav-btn-next-album {
        span {
            span {
                display: none;
                /*
                white-space: nowrap;
                position: absolute;
                top: 0;
                right: -0.6rem;
                transform: translateX(100%);
                width: 20vw;
                display: inline-block;
                @include fader(right, $back-color--albums, 4rem);
                overflow: hidden;
                display: none;
                */
            }
        }

    }


    .album-nav-btn-next-album {
        .album--last-element & {
            display: flex;
        }
    }
    .album-nav-btn-prev-album {
        .album--first-element & {
            display: flex;
        }
    }
}

// the containers
.album-index-container,
.album-info-container {
    overflow: hidden;
    position: fixed;
    padding: 0;
    top: $header-height;
    bottom: 0;
    width: 100%;
    background-color: lighten($back-color--albums, 2);
    transform: translateY(100%);
    visibility: hidden;
    transition: transform $trans-time--s $trans-ease--layer,
                visibility 0s $trans-ease--layer $trans-time--s;
    overflow-y: auto; // overwrite the JS-STuff with this
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    mask-image: linear-gradient(to bottom, transparent 0%, black #{$header-height});

    @supports (backdrop-filter: blur(5px)) {
        background: rgba(darken($back-color--albums, 4), 0.6);
        backdrop-filter: blur(2rem);
    }

    &.active {
        transition: opacity $trans-time--s $trans-ease--default;
        opacity: 1;
    }

    .album-has-info & {
        height: auto;
    }

    @include respond-to(mobile) {
        z-index: 3; // Show on top of headline
    }

    @include respond-to(desktop) {
        width: 50%;
        top: 0;
        min-width: 50rem;
        max-width: 100vw;
        height: 100%;
        transform: translateX(-100%);
    }
}

.album-info-container {
    * + h2 {
        margin-top: $padding-v;
    }

    h3 {
        @include textstyle--b;
    }

    & > *:first-child {
        margin-top: $header-height;
    }

    & > *:last-child {
        padding-bottom: $header-height + $padding-v;
    }

    left: 0;
    right: auto;

    h3, h2, p {
        padding: 0 $padding-h;
    }

    .has-album-info-opened & {
        transform: none;
        visibility: visible;
        transition: transform $trans-time--s $trans-ease--layer;
    }

    @include respond-to(decent) {
        & > *:first-child {
            margin-top: $header-height + $padding-v;
        }
    }
}

.album-index-container {
    right: 0;
    left: auto;

    .has-album-index-opened & {
        transform: none;
        visibility: visible;
        transition: transform $trans-time--s $trans-ease--layer;
    }

    @include respond-to(desktop) {
        transform: translateX(100%);
    }
}

.album-index-container-container {
    flex-wrap: wrap;
    display: flex;
    max-width: 100vw;
    padding: 4rem;

    a {
        width: 50%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 1rem 2rem;
        align-items: center;

        .media-box {
            img {
                width: 100%;
            }
        }
    }

    @include respond-to(desktop) {
        padding: 8rem 4rem;

        a {
            width: 33%;
        }
    }
}
