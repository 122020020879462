// Basics
html,
body {
    &.has-modals {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}

body {
    background-color: $back-color--lander;

    //overflow-y: scroll; // alweys show scrollbar so nothing jumps on windows
    @include respond-to(desktop) {
        &.has-albums,
        &.has-menu:not(.has-categories) {
            overflow: hidden;
        }
    }
}

// Font and Grid scaling
// Resizing for fontsizes
html,
body {
    // Apply fallback fontsize
    font-size: 10px;

    // Desktop
    @include fluid-type(font-size, $bp--large, 2000px, 10px, 14px);

    // stop resizing above certain threshold
    @media only screen and (min-width: 2000px) {
        font-size: 14px;
    }

    &.is-loading {
        cursor: wait;

        a,
        button,
        [type="button"] {
            cursor: wait;
        }
    }
}


// Define breakpoints for JS
// https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
body:before {
    content: 'mobile';
    display: none;
}

@include respond-to(desktop) {
    body:before {
        content: 'desktop';
    }
}

// Helper classes
.hidden-on-mobile {
    @include respond-to(mobile) {
        display: none !important;
    }
}

.hidden-on-desktop {
    @include respond-to(desktop) {
        display: none !important;
    }
}

.chrome .hidden-on-mobile-chrome {
    @include respond-to(mobile) {
        display: none !important;
    }
}

.chrome .hidden-on-desktop-chrome {
    @include respond-to(desktop) {
        display: none !important;
    }
}
