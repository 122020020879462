/* albums */
.albums {
    @include respond-to(desktop) {
        background: $back-color--albums;
        overflow: hidden;
    }
}

.album-wrapper {
    display: none;

    &.album-wrapper--opend {
        display: block;
        @include respond-to(mobile) {
            min-height: calc(100vh + 1px);
        }
    }
}

.album {
    color: $text-color--albums;

    svg {
        fill: $text-color--albums;
    }

    .album-header {
        cursor: pointer;
        height: $header-height; // let more lines overflow if needed
        margin-bottom: $header-height; // dont cover footer

        @include respond-to(desktop) {
            margin-bottom: 0;
        }
    }

    .album-content {
        position: relative;
        margin-top: $header-height * -1 * 2; // pull up content to show sticky header on top
        margin-bottom: $header-height * -1 * 2; // pull up footer to show sticky footer on top
        min-height: 20rem;
    }

    .album-footer {
        display: flex;
        margin-top: $header-height; // show footer below header
        transition: opacity $trans-time--m $trans-ease--default;

        > * {
            position: relative;
        }

        // show gradient behind buttons on opened modals
        &:before {
            content: "";
            position: fixed;
            bottom: -1px;
            left: 0;
            right: 0;
            height: $header-height * 1.5;
            display: block;
            background: linear-gradient(to top, rgba($back-color--albums, 0.8) 50%, rgba($back-color--albums, 0));
            opacity: 0;
            transition: opacity $trans-time--m $trans-ease--default;
            visibility: hidden;

            @supports (pointer-events: none) {
                visibility: visible;
                pointer-events: none;
            }
        }

        @include respond-to(desktop) {
            margin-top: 0;
            align-items: center;
            opacity: 0;
            pointer-events: none;
        }
    }

    // dont show index for pre and post
    &.album--focused-post,
    &.album--focused-pre {
        .album-info-container,
        .album-index-container {
            display: none;
        }
    }

    .album-content--loaded ~ .album-footer {
        @include respond-to(desktop) {
            opacity: 1;
            pointer-events: all;
        }
    }

    // dont show elements, if there is nothing loaded
    .album-content--loading {
        ~ .album-footer,
        ~ .album-header {
            display: none;
        }
        .album-index-container,
        .album-info-container,
        .album-images,
        .album-footer {
            display: none;
        }
    }

    .album-images .media-box {
        opacity: 0.15;
        transition: opacity $trans-time--m $trans-ease--default;

        img {
            display: block;
            width: 100%;
            min-height: 1px; /*has to have at least 1px */
        }

        &.active {
            opacity: 1;

            .has-modals & {
                opacity: 0.35;
            }
        }

        &.post-active {
            opacity: 0.9;

            .has-modals & {
                opacity: 0.35;
            }
        }

        @include respond-to(mobile) {
            background: $back-color--album-media-box;
        }

        @include respond-to(desktop) {
            opacity: 1;
            background: none;
        }
    }

    @include respond-to(mobile) {
        width: 100%;
        background: $back-color--albums;

        // only show active album while transitioning album layer
        visibility: hidden;

        .has-albums & {
            visibility: visible;
            transition: visibility 0s $trans-ease--layer $trans-time--layer;
        }

        // delay opacity animation for header
        .album-header {
            transition: transform $trans-time--l $trans-ease--slideshow,
            opacity $trans-time--s $trans-ease--default 0.15s;
        }

        .album-content {
            transition: transform $trans-time--l $trans-ease--slideshow,
            opacity $trans-time--s $trans-ease--default;
        }

        // fix chrome bug where sticky header is not clickable
        .album-header,
        [data-action="open-detail-info"],
        [data-action="open-detail-index"] {
            &:after {
                content: "";
                height: $header-height;
                width: 50vw;
                position: fixed;
                bottom: 0;
                left: 0;
                visibility: hidden;
            }
        }

        [data-action="open-detail-index"] {
            &:after {
                right: 0;
                left: auto;
            }
        }

        .album-header {
            &:after {
                width: auto;
                top: 0;
                right: 0;
            }
        }

        &.album--focused-post {
            header,
            footer {
                opacity: 0;
                pointer-events: none;
            }
        }

        // fade out type if not scrolled for some time
        &.album--focused {
            z-index: 1;

            // show active albums right away while transitioning album layer
            transition: none;

            header,
            footer {
                z-index: 4;
            }

            // fade out type if not scrolled for some time
            &:not(.has-album-index-opened):not(.has-album-info-opened) {
                header,
                footer {
                    > * {
                        transition: opacity $trans-time--l $trans-ease--default;

                        .no-safari .is-scrolled:not(.is-scrolling) & { // limit to non-safari due to performance problems here
                            transition: opacity $trans-time--l $trans-ease--default 4s;
                            opacity: 0;
                        }
                    }
                }
            }

            .album-header,
            [data-action="open-detail-info"],
            [data-action="open-detail-index"] {
                // fix chrome bug where sticky header is not clickable
                &:after {
                    visibility: visible;
                }
            }
        }

        &.album--focused-pre {
            // show next albums right away while transitioning album layer
            transition: none;
        }

        // prevent animation if we have some content
        // or if we are transitioning albums out
        &:not([data-loaded="true"]),
        body:not(.has-albums) & {
            .album-footer,
            .album-header,
            .album-content {
                transition: none;
            }
        }

        &.album--focused + * + * + &.album--focused-pre, // assuming we have 3 sections within one viewport max plus some time for fast scrolling
        &.album--focused + * + &.album--focused-pre,
        &.album--focused + &.album--focused-pre {
            > * {
                transform: translate3d(0, 50vh, 0);
            }
        }

        // prevent close buttons hanging in the middle
        &.has-album-info-opened,
        &.has-album-index-opened {
            .album-content {
                // compensate for missing footer
                margin-bottom: 0;
            }

            .album-footer {
                // keep close icons on bottom even if album is already scrolled further
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;

                &:before {
                    opacity: 1;
                }
            }
        }

        // make last image be scrollable to center as well
        &:last-child {
            margin-bottom: 40vh;
        }
    }

    @include respond-to(desktop) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transform-origin: center center;
        overflow: hidden;
        z-index: 1;
        pointer-events: none; // dont interact with non-active albums

        // reset position sticky
        .album-header,
        .album-footer {
            position: relative;
            top: auto;
            bottom: auto;
            flex: 0 0 auto;
            margin-top: 0;
            margin-bottom: 0;
        }

        .album-content {
            flex: 1 0 auto;
            margin: 0;
            position: relative;
            z-index: -1; // put below album title
        }

        .album-images {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        // this fades the images itself in @ opentime of one album
        // we need this, so reopen album dont look like ass
        &.album--fade-in {
            .media-box {
                opacity: 0 !important;
            }
        }

        // handle album display
        .has-albums & {
            transition: transform $trans-time--s $trans-ease--slideshow,
                        opacity $trans-time--s $trans-ease--slideshow;

            > * {
                transition: none;
            }

            .album-images {
                transition: transform $trans-time--s + 0.6s $trans-ease--slideshow;
            }

            .album-footer {
                transition: transform $trans-time--s + 0.15s $trans-ease--slideshow;
            }

            .btn--layer-toggle {
                opacity: 0;
                visibility: hidden;
            }

            // show focused album
            &.album--focused {
                opacity: 1;
                pointer-events: all;
                transition: transform $trans-time--s $trans-ease--slideshow,
                            opacity $trans-time--s $trans-ease--slideshow;

                .btn--layer-toggle {
                    transition: opacity $trans-time--m $trans-ease--slideshow $trans-time--m;
                    opacity: 1;
                    visibility: visible;
                }
            }

            // albums before
            &.album--focused-post {
                transform: translate3d(-10vw, 0, 0);

                .album-images {
                    transform: translate3d(-90vw, 0, 0);
                }

                .album-footer {
                    transform: translate3d(-10vw, 0, 0);
                }
            }

            // albums after
            &.album--focused-pre {
                transform: translate3d(10vw, 0, 0);
                visibility: hidden;
                transition: transform $trans-time--s $trans-ease--slideshow,
                            opacity $trans-time--s $trans-ease--slideshow,
                            visibility 0s linear $trans-time--s;

                .album-images {
                    transform: translate3d(90vw, 0, 0);
                }

                .album-footer {
                    transform: translate3d(10vw, 0, 0);
                }
            }
        }

        // delay fade out if album modal is closing
        body:not(.has-albums) & {
            transition: opacity $trans-time--s $trans-ease--default $trans-time--s;
        }
    }
}

// the loading animation
.album-content-loading-spinner {
    height: 50vh;
    transition: opacity $trans-time--l $trans-ease--default,
                visibility 0s linear $trans-time--l;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .album-content--loaded & {
        opacity: 0;
        visibility: hidden;
        position: fixed;
    }

    @include respond-to(desktop) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        height: 98vh;

        .ios & {
            height: 90vh;
        }
    }
}


