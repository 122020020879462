// custom componeent styles for templates
// only edit in template branches
// make sure _fontface is uncommented in template branch

@font-face {
    font-family: 'GT-America-Expanded-Medium';
    src: url('fonts/GT-America-Expanded-Medium.woff2') format('woff2'),
         url('fonts/GT-America-Expanded-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT-America-Expanded-Medium-Italic';
    src: url('fonts/GT-America-Expanded-Medium-Italic.woff2') format('woff2'),
         url('fonts/GT-America-Expanded-Medium-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
