.media-box {
    overflow: hidden;

    &.media-box--ratio {
        position: relative;
        padding-bottom: 100%;

        img {
            position: absolute;
        }
    }

    @include respond-to(desktop) {
        &.media-box--ratio {
            .album & {
                position: static;
                padding-bottom: 0 !important;

                img {
                    position: static;
                }
            }
        }
    }
}

.blur-up {
    transition: opacity $trans-time--l $trans-ease--default,
                transform $trans-time--m $trans-ease--default;
    transform: scale(1.02);
    opacity: 0;

    &.lazyloaded {
        transform: none;
        opacity: 1;
    }

    .safari & {
        transform: none;
    }

    .ios & {
        transition: none; // animation is too heavy for safari devices
    }
}

.lazy-box {
    background: darken($back-color--categories, 2);
}

.lazy-box--spinner {
    &.lazy-box--loading {
        overflow: visible;

        &:after {
            content: "";
            position: absolute;
            top: calc(50% - 1.2rem);
            left: calc(50% - 1.2rem);
            @include spinner;

            @include respond-to(mobile) {
                width: 1.6rem;
                height: 1.6rem;
                top: calc(50% - 0.8rem);
                left: calc(50% - 0.8rem);
            }
        }
    }
}
