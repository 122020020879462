@mixin absolute-layer() {
    position: absolute;
    bottom: auto;
    max-height: 0;
    visibility: hidden;
    overflow-y: unset;
    // transition: transform $trans-time--layer $trans-ease--layer, // no need for transitioning out since still looking good without
    //             visibility 0s $trans-ease--layer $trans-time--layer,
    //             max-height 0s $trans-ease--layer $trans-time--layer;
}

@mixin show-absolute-layer() {
    max-height: 99999999vh;
    min-height: 100vh;
    visibility: visible;
    overflow: visible;
    -webkit-overflow-scrolling: unset;
    // transition: transform $trans-time--layer $trans-ease--layer; // no need for transitioning out since still looking good without
}

.layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 1;

    // handle layer display
    transform: translate3d(0, 100vh, 0);
    transition: transform $trans-time--layer $trans-ease--layer;

    // prevent scrolling on opened modals
    .has-modals & {
        overflow-y: hidden;
    }

    &.layer--lander {
        // layer lander is always shown in the background
        transform: none;

        .has-imprint &,
        .has-info & {
            transform: translate3d(0, calc(100% - #{$header-height}), 0);
        }

        @include respond-to(mobile) {
            .has-albums:not(.has-categories) &,
            .has-menu.has-categories & {
                transform: translate3d(0, #{$header-height * -1 * $parra-offset}, 0);
            }
        }
    }

    &.layer--menu {
        overflow: auto;

        .has-menu & {
            transform: none;
        }

        .has-menu.has-categories & {
            transform: translate3d(0, #{$header-height * -1}, 0);
        }

        .has-menu.has-albums & {
            transform: translate3d(0, #{$header-height * -1 * (1 + $parra-offset)}, 0);
        }

        .has-menu.has-imprint &,
        .has-menu.has-info & {
            transform: translate3d(0, 100%, 0);
        }

        @include respond-to(mobile) {
            // show menu at the bottom
            transform: translate3d(0, calc(100% - #{$header-height}), 0);
        }
    }

    &.layer--categories {
        // position absolute to improve overall performance
        @include absolute-layer;

        .has-categories & {
            transform: none;
            @include show-absolute-layer;
        }

        .has-categories.has-albums & {
            transform: translate3d(0, #{$header-height * -1 * ($parra-offset * 2)}, 0);
        }


        @include respond-to(desktop) {
            top: $header-height; // still show header on top

            .has-imprint &,
            .has-info & {
                transform: translate3d(0, 100vh, 0);
            }
        }
    }

    &.layer--albums {
        .has-albums & {
            transform: none;
        }

        // position absolute to improve mobile performance
        @include respond-to(mobile) {
            @include absolute-layer;
            opacity: 0;
            transform: translate3d(0, 50vh, 0);

            .has-albums & {
                @include show-absolute-layer;
                transition: transform $trans-time--layer $trans-ease--layer,
                            opacity $trans-time--layer $trans-ease--layer;
                opacity: 1;
            }
        }
    }

    // hide some layers while transitioning to improve overall performance
    &:not(.layer--albums) {
        .has-albums & {
            visibility: hidden;
        }

        @include respond-to(desktop) {
            .has-albums & {
                transition: transform $trans-time--layer $trans-ease--layer,
                            visibility 0s $trans-ease--layer $trans-time--layer;
            }
        }
    }

    &:not(.layer--albums):not(.layer--categories) {
        @include respond-to(mobile) {
            .has-categories:not(.has-albums) & {
                transition: transform $trans-time--layer $trans-ease--layer,
                visibility 0s $trans-ease--layer $trans-time--layer;
                visibility: hidden;
            }
        }
    }

    &.layer--info {
        // let info come in from top
        bottom: $header-height;
        transform: translate3d(0, -100%, 0);

        .has-info & {
            transform: none;
        }

        // animate children parrallax as layer comes in
        > header,
        > section {
            transition: transform $trans-time--layer $trans-ease--layer;
            transform: translate3d(0, 50vh, 0);

            .has-info & {
                transform: none;
            }
        }

        img {
            width: 100%;
            display: block;
        }
    }

    &.layer--imprint {
        // let imprint come in from top
        bottom: $header-height;
        transform: translate3d(0, -100%, 0);

        .has-imprint & {
            transform: none;
        }

        // animate children parrallax as layer comes in
        > header,
        > section {
            transition: transform $trans-time--layer $trans-ease--layer;
            transform: translate3d(0, 50vh, 0);

            .has-imprint & {
                transform: none;
            }
        }
    }
}

// fade body to same background color as albums to have nice motion scroll overflow
// and to have correct transition for albums
body.has-albums {
    background: $back-color--categories;

    // correct transitions for albums coming from main slideshow
    &:not(.has-categories) {
        transition: background 0s $trans-ease--layer $trans-time--layer;
    }

    @include respond-to(desktop) {
        transition: background 0s $trans-ease--layer $trans-time--layer;
    }
}
