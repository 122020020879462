.imprint,
.info {
    background: $back-color--info;
    color: $text-color--info;

    svg {
        fill: $text-color--info;
    }

    @supports (pointer-events: none) {
        &:after {
            content: '';
            position: sticky;
            display: block;
            width: 100%;
            bottom: 0;
            height: 6rem;
            pointer-events: none;
            background: linear-gradient(to top, rgba($back-color--info, 0.6), rgba($back-color--info, 0));
        }

        header {
            > * {
                position: relative;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to bottom, rgba($back-color--info, 0.6), rgba($back-color--info, 0));
            }
        }
    }
}

.info-content {
    padding: $padding-v $padding-h;

    @include respond-to(desktop) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.info-content__contact {
    .media-box {
        margin: $padding-v;
        background: darken($back-color--info, 2);
    }

    @include respond-to(desktop) {
        width: 33%;
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;

        .media-box {
            margin: $padding-v 0;
            width: 90%;
            // margin-top: 0;
            // margin-bottom: $padding-v;
            // order: -1;
        }
    }
}

.info-content__info {
    @include respond-to(desktop) {
        width: 60%;
    }
}

.info-content__clients {
    margin-top: $padding-v * 2;
}
