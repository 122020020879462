header {
    min-height: $header-height;
    padding: ($header-height - ($font-size--a * $line-height)) / 2 $padding-h; // header height minus full text height devided by two
    display: flex;
    align-items: baseline;
    position: relative;
    transition: transform $trans-time--layer $trans-ease--layer;
    z-index: 2;

    @supports(position: sticky) {
        position: sticky;
        top: 0;

        @include respond-to(desktop) {
            .layer--categories & {
                top: -1px; // fix blitzer
            }
        }
    }

    &[type="button"] {
        cursor: pointer; // make clickable headers clickable on iOS as well
    }

    h1,
    h2,
    h3 {
        flex: 1 1 auto;
    }

    button {
        flex: 0 0 auto;
    }

    i {
        font-style: inherit;
    }
}
