@mixin textstyle--a {
    font-size: $font-size--a;
    font-weight: $font-weight--a;
}

@mixin textstyle--b {
    font-size: $font-size--b;
    font-weight: $font-weight--b;
}

@mixin textstyle--c {
    font-size: $font-size--c;
    font-weight: $font-weight--c;
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: $font-family--default;
    line-height: $line-height;
    color: $text-color--lander;
    @include textstyle--b;
}

button,
input,
select,
textarea {
    font-family: $font-family--default;
}

h1,
h2,
h3 {
    @include textstyle--a;
    padding: 0;
    margin: 0;
}

small {
    @include textstyle--c;
}

p {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}


.markdown-content {
    a {
        border-bottom: 1px solid currentColor;
    }

    ul {
        margin-left: 1em;
        margin-bottom: 1em;

        li {
            list-style: square;
            margin-bottom: 0.5em;
        }
    }

    p, h3, h2, h1, ul {
        margin-bottom: 1em;
    }

    @include respond-to(mobile) {
        p,
        ul,
        a {
            font-size: $font-size--a;
        }

        p {
            padding-right: $padding-h; // prevent imprint link to cover text
        }
    }

    @include respond-to(big-desktop) {
        p,
        ul,
        a {
            font-size: $font-size--a;
        }
    }
}
