/* width */

.no-mac.no-touch {
    ::-webkit-scrollbar {
        width: $scrollbar-width;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $scrollbar-track-color;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
    }
}
