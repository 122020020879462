// marlen

// colors
$back-color--default: #fff;
$text-color--default: #000;
$back-color--secondary: #ffff00;
$text-color--secondary: #000;

$back-color--header: $back-color--default;
$text-color--header: $text-color--default;
$back-color--lander: $back-color--default;
$text-color--lander: $text-color--default;
$back-color--menu: $back-color--secondary;
$text-color--menu: $text-color--default;
$back-color--categories: $back-color--secondary;
$text-color--categories: $text-color--secondary;
$back-color--albums: $back-color--secondary;
$text-color--albums: $text-color--secondary;
$back-color--info: $back-color--secondary;
$text-color--info: $text-color--secondary;
$back-color--album-media-box: #121211;

// grid
$grid-gap-mobile-raw: 2;
$grid-gap-mobile-h: $grid-gap-mobile-raw * 1vw;

$grid-gap-desktop-raw: 0.3; // needs to be the same and vw for masonry
$grid-gap-desktop-h: $grid-gap-desktop-raw * 1vw;
$grid-gap-desktop-v: $grid-gap-desktop-raw * 1vw;

// layout
$fading-header: false; // Add some gradient to fade content behind header

// fonts
$font-size--a: 1.6rem;
$font-size--b: 1.25rem;
$font-size--c: 1rem;

$line-height: 1.18;
$font-weight--a: 100;

$font-family--default: "GT-America-Expanded-Medium", Helvetica, Arial, sans-serif;
$font-family--secondary: "GT-America-Expanded-Medium-Italic", Helvetica, Arial, sans-serif;
