// colors
$back-color--default: #1c1c1b;
$text-color--default: #fff;

// layers
$back-color--header: $back-color--default;
$text-color--header: $text-color--default;
$back-color--lander: $back-color--default;
$text-color--lander: $text-color--default;
$back-color--menu: $back-color--default;
$text-color--menu: $text-color--default;
$back-color--categories: $back-color--default;
$text-color--categories: $text-color--default;
$back-color--albums: $back-color--default;
$text-color--albums: $text-color--default;
$back-color--info: $back-color--default;
$text-color--info: $text-color--default;
$back-color--album-media-box: darken($back-color--default, 4);

// layout
$header-height: 5rem;
$padding-h: 1rem;
$padding-v: 2rem;

$grid-gap-mobile-raw: 2;
$grid-gap-mobile-h: $grid-gap-mobile-raw * 1vw; // needs to be vw for masonry
$grid-gap-mobile-v: 8vw; // min-height of text and bottom gap

$grid-gap-desktop-raw: 1; // needs to be the same and vw for masonry
$grid-gap-desktop-h: $grid-gap-desktop-raw * 1vw;
$grid-gap-desktop-v: $grid-gap-desktop-raw * 1vw;

$lander-peak: 20vh; // shown part of header while nav is open
$parra-offset: 0.5;

$fading-header: true; // Add some gradient to fade content behind header

// fonts
$font-size--a: 2.4rem;
$font-size--b: 1.7rem;
$font-size--c: 1rem;

$line-height: 1.18;
$font-weight--a: 100;
$font-weight--b: $font-weight--a;
$font-weight--c: $font-weight--a;

$font-family--default: "Favorit-MediumExtended", Helvetica, Arial, sans-serif;
$font-family--secondary: $font-family--default;

// animation
$trans-time--s: 0.25s;
$trans-time--m: 0.5s;
$trans-time--l: 0.75s;
$trans-time--xl: 1s;
$trans-time--layer: 0.35s;
$trans-ease--layer: cubic-bezier(.1,.9,.3,1);
$trans-ease--slideshow: cubic-bezier(.1,.9,.3,1);
$trans-ease--default: cubic-bezier(.1,.6,.4,1);

// scrollbars (webkit based)
$scrollbar-track-color: darken($back-color--default, 10);
$scrollbar-thumb-color: lighten($back-color--default, 20);
$scrollbar-width: 10px;



