.cookie-form {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $back-color--categories;
    padding: $padding-h;
    z-index: 10;
    @include textstyle--c;

    .btn {
        background: rgba(0,0,0,0.8);
        border: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        width: 100%;
        text-align: center;
        @include textstyle--c;
        margin: 1rem 0;
    }

    @include respond-to(desktop) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .btn {
            flex: 0 0 auto;
            width: auto;
            margin: 0;
            padding: 0;
            background: transparent;
        }
    }
}
