.menu {
    background: rgba(darken($back-color--menu, 4), 0.97);
    color: $text-color--menu;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: $header-height; // still show header on top

    svg {
        fill: $text-color--menu;
    }

    @supports (backdrop-filter: blur(5px)) {
        background: rgba(darken($back-color--menu, 4), 0.6);
        backdrop-filter: blur(2rem);
    }

    @include respond-to(desktop) {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        top: calc(#{$header-height} + #{$lander-peak}); // still show header on top
    }

    & > * {
        transition: margin 0.35s ease-in-out;
    }

    ul {
        padding: 0;
        margin: $padding-v $padding-h;
        list-style: none;
        @include textstyle--a;

        li {
            //  &:last-child {
            //      margin-top: $font-size--a * $line-height;
            //
            //      a small {
            //          display: none;
            //      }
            //  }

            a {
                display: block;

                /*
                &:hover {
                    color: #ddd;
                }

                &:active {
                    color: #fff;
                }*/
            }
        }
    }

    /*
    .has-menu.has-categories & {
        height: 100%;
    }

    .has-menu & {
        .menu-close {
            transform: rotate(0deg);
        }

        ul {
            bottom: 2rem;
            opacity: 1;
        }
    }

    .has-categories & {
        background: #000;
        opacity: 1;

        /*
        > .menu-title,
        > .menu-close {
            margin-top: -0.75rem;
        }
    }*/
}
