*,
*:after,
*:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

/* Remove outline of input elements */
a,
button,
input,
select,
textarea {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 */
input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
    overflow: auto;
}

/* CSS fix for IE selectbox */
select::-ms-expand {
    background: none;
    border: none;
}

li {
    list-style: none;
}

/* Hide big iOS play button */
video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}



html, body{
    height: 100%;
}

body{
    font-size: 62.5%;
}