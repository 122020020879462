@font-face {
  font-family: 'GT-America-Expanded-Medium';
  src: url("fonts/GT-America-Expanded-Medium.woff2") format("woff2"), url("fonts/GT-America-Expanded-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT-America-Expanded-Medium-Italic';
  src: url("fonts/GT-America-Expanded-Medium-Italic.woff2") format("woff2"), url("fonts/GT-America-Expanded-Medium-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

/*! Flickity v2.1.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/* Remove outline of input elements */
a,
button,
input,
select,
textarea {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/* CSS fix for IE selectbox */
select::-ms-expand {
  background: none;
  border: none;
}

li {
  list-style: none;
}

/* Hide big iOS play button */
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

html, body {
  height: 100%;
}

body {
  font-size: 62.5%;
}

html.has-modals,
body.has-modals {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  background-color: #fff;
}

@media only screen and (min-width: 600px) {
  body.has-albums, body.has-menu:not(.has-categories) {
    overflow: hidden;
  }
}

html,
body {
  font-size: 10px;
  font-size: 10px;
}

@media screen and (min-width: 600px) {
  html,
  body {
    font-size: calc(10px + 4 * (100vw - 600px) / 1400);
  }
}

@media screen and (min-width: 2000px) {
  html,
  body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 2000px) {
  html,
  body {
    font-size: 14px;
  }
}

html.is-loading,
body.is-loading {
  cursor: wait;
}

html.is-loading a,
html.is-loading button,
html.is-loading [type="button"],
body.is-loading a,
body.is-loading button,
body.is-loading [type="button"] {
  cursor: wait;
}

body:before {
  content: 'mobile';
  display: none;
}

@media only screen and (min-width: 600px) {
  body:before {
    content: 'desktop';
  }
}

@media only screen and (max-width: 599px) {
  .hidden-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .hidden-on-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 599px) {
  .chrome .hidden-on-mobile-chrome {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .chrome .hidden-on-desktop-chrome {
    display: none !important;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "GT-America-Expanded-Medium", Helvetica, Arial, sans-serif;
  line-height: 1.18;
  color: #000;
  font-size: 1.25rem;
  font-weight: 100;
}

button,
input,
select,
textarea {
  font-family: "GT-America-Expanded-Medium", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3 {
  font-size: 1.6rem;
  font-weight: 100;
  padding: 0;
  margin: 0;
}

small {
  font-size: 1rem;
  font-weight: 100;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.markdown-content a {
  border-bottom: 1px solid currentColor;
}

.markdown-content ul {
  margin-left: 1em;
  margin-bottom: 1em;
}

.markdown-content ul li {
  list-style: square;
  margin-bottom: 0.5em;
}

.markdown-content p, .markdown-content h3, .markdown-content h2, .markdown-content h1, .markdown-content ul {
  margin-bottom: 1em;
}

@media only screen and (max-width: 599px) {
  .markdown-content p,
  .markdown-content ul,
  .markdown-content a {
    font-size: 1.6rem;
  }
  .markdown-content p {
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .markdown-content p,
  .markdown-content ul,
  .markdown-content a {
    font-size: 1.6rem;
  }
}

/* width */
.no-mac.no-touch {
  /* Track */
  /* Handle */
}

.no-mac.no-touch ::-webkit-scrollbar {
  width: 10px;
}

.no-mac.no-touch ::-webkit-scrollbar-track {
  background: #020202;
}

.no-mac.no-touch ::-webkit-scrollbar-thumb {
  background: #50504d;
}

header {
  min-height: 5rem;
  padding: 1.556rem 1rem;
  display: flex;
  align-items: baseline;
  position: relative;
  transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
  z-index: 2;
}

@supports (position: sticky) {
  header {
    position: sticky;
    top: 0;
  }
  @media only screen and (min-width: 600px) {
    .layer--categories header {
      top: -1px;
    }
  }
}

header[type="button"] {
  cursor: pointer;
}

header h1,
header h2,
header h3 {
  flex: 1 1 auto;
}

header button {
  flex: 0 0 auto;
}

header i {
  font-style: inherit;
}

footer {
  min-height: 5rem;
  padding: 1.556rem 1rem;
  display: flex;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

footer h1,
footer h2,
footer h3,
footer p {
  flex: 1 1 auto;
}

footer button {
  flex: 0 0 auto;
}

footer i {
  font-style: inherit;
}

.spinner {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.2em solid #000;
  opacity: 0;
  animation: spinner 0.9s infinite 0.2s ease-in-out;
}

.album-content--loaded .spinner {
  animation: none !important;
  visibility: hidden;
}

@keyframes spinner {
  0%, 100% {
    opacity: 1;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

.media-box {
  overflow: hidden;
}

.media-box.media-box--ratio {
  position: relative;
  padding-bottom: 100%;
}

.media-box.media-box--ratio img {
  position: absolute;
}

@media only screen and (min-width: 600px) {
  .album .media-box.media-box--ratio {
    position: static;
    padding-bottom: 0 !important;
  }
  .album .media-box.media-box--ratio img {
    position: static;
  }
}

.blur-up {
  transition: opacity 0.75s cubic-bezier(0.1, 0.6, 0.4, 1), transform 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
  transform: scale(1.02);
  opacity: 0;
}

.blur-up.lazyloaded {
  transform: none;
  opacity: 1;
}

.safari .blur-up {
  transform: none;
}

.ios .blur-up {
  transition: none;
}

.lazy-box {
  background: #f5f500;
}

.lazy-box--spinner.lazy-box--loading {
  overflow: visible;
}

.lazy-box--spinner.lazy-box--loading:after {
  content: "";
  position: absolute;
  top: calc(50% - 1.2rem);
  left: calc(50% - 1.2rem);
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.2em solid #000;
  opacity: 0;
  animation: spinner 0.9s infinite 1s ease-in-out;
}

@media only screen and (max-width: 599px) {
  .lazy-box--spinner.lazy-box--loading:after {
    width: 1.6rem;
    height: 1.6rem;
    top: calc(50% - 0.8rem);
    left: calc(50% - 0.8rem);
  }
}

.btn {
  display: inline-block;
  color: inherit;
  background: none;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  box-shadow: none;
  border-radius: 0;
  width: auto;
  font-size: 1.6rem;
  font-weight: 100;
  height: auto;
  line-height: 1.18;
}

.btn svg {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.btn.btn--s {
  font-size: 1.25rem;
  font-weight: 100;
}

.btn.btn--s svg {
  width: 1.25rem;
  height: 1.25rem;
}

.btn i {
  font-style: inherit;
}

.btn.btn--layer-toggle {
  align-self: center;
}

body:not(.has-menu) .layer--menu .btn.btn--layer-toggle {
  transform: scale(-1);
}

.has-categories .layer--menu .btn.btn--layer-toggle svg {
  opacity: 0;
}

header .btn:not(:first-child) {
  margin-left: 1rem;
}

.btn[disabled] {
  cursor: default;
}

[type="button"] {
  cursor: pointer;
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
}

.has-modals .layer {
  overflow-y: hidden;
}

.layer.layer--lander {
  transform: none;
}

.has-imprint .layer.layer--lander,
.has-info .layer.layer--lander {
  transform: translate3d(0, calc(100% - 5rem), 0);
}

@media only screen and (max-width: 599px) {
  .has-albums:not(.has-categories) .layer.layer--lander,
  .has-menu.has-categories .layer.layer--lander {
    transform: translate3d(0, -2.5rem, 0);
  }
}

.layer.layer--menu {
  overflow: auto;
}

.has-menu .layer.layer--menu {
  transform: none;
}

.has-menu.has-categories .layer.layer--menu {
  transform: translate3d(0, -5rem, 0);
}

.has-menu.has-albums .layer.layer--menu {
  transform: translate3d(0, -7.5rem, 0);
}

.has-menu.has-imprint .layer.layer--menu,
.has-menu.has-info .layer.layer--menu {
  transform: translate3d(0, 100%, 0);
}

@media only screen and (max-width: 599px) {
  .layer.layer--menu {
    transform: translate3d(0, calc(100% - 5rem), 0);
  }
}

.layer.layer--categories {
  position: absolute;
  bottom: auto;
  max-height: 0;
  visibility: hidden;
  overflow-y: unset;
}

.has-categories .layer.layer--categories {
  transform: none;
  max-height: 99999999vh;
  min-height: 100vh;
  visibility: visible;
  overflow: visible;
  -webkit-overflow-scrolling: unset;
}

.has-categories.has-albums .layer.layer--categories {
  transform: translate3d(0, -5rem, 0);
}

@media only screen and (min-width: 600px) {
  .layer.layer--categories {
    top: 5rem;
  }
  .has-imprint .layer.layer--categories,
  .has-info .layer.layer--categories {
    transform: translate3d(0, 100vh, 0);
  }
}

.has-albums .layer.layer--albums {
  transform: none;
}

@media only screen and (max-width: 599px) {
  .layer.layer--albums {
    position: absolute;
    bottom: auto;
    max-height: 0;
    visibility: hidden;
    overflow-y: unset;
    opacity: 0;
    transform: translate3d(0, 50vh, 0);
  }
  .has-albums .layer.layer--albums {
    max-height: 99999999vh;
    min-height: 100vh;
    visibility: visible;
    overflow: visible;
    -webkit-overflow-scrolling: unset;
    transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
    opacity: 1;
  }
}

.has-albums .layer:not(.layer--albums) {
  visibility: hidden;
}

@media only screen and (min-width: 600px) {
  .has-albums .layer:not(.layer--albums) {
    transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1), visibility 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
  }
}

@media only screen and (max-width: 599px) {
  .has-categories:not(.has-albums) .layer:not(.layer--albums):not(.layer--categories) {
    transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1), visibility 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
    visibility: hidden;
  }
}

.layer.layer--info {
  bottom: 5rem;
  transform: translate3d(0, -100%, 0);
}

.has-info .layer.layer--info {
  transform: none;
}

.layer.layer--info > header,
.layer.layer--info > section {
  transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
  transform: translate3d(0, 50vh, 0);
}

.has-info .layer.layer--info > header, .has-info
.layer.layer--info > section {
  transform: none;
}

.layer.layer--info img {
  width: 100%;
  display: block;
}

.layer.layer--imprint {
  bottom: 5rem;
  transform: translate3d(0, -100%, 0);
}

.has-imprint .layer.layer--imprint {
  transform: none;
}

.layer.layer--imprint > header,
.layer.layer--imprint > section {
  transition: transform 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
  transform: translate3d(0, 50vh, 0);
}

.has-imprint .layer.layer--imprint > header, .has-imprint
.layer.layer--imprint > section {
  transform: none;
}

body.has-albums {
  background: #ffff00;
}

body.has-albums:not(.has-categories) {
  transition: background 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
}

@media only screen and (min-width: 600px) {
  body.has-albums {
    transition: background 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
  }
}

@media only screen and (min-width: 600px) {
  .slideshow {
    opacity: 0;
  }
  .slideshow:after {
    content: 'flickity';
    display: none;
    /* hide :after */
  }
  .slideshow.flickity-enabled {
    opacity: 1;
  }
  .slideshow .slideshow-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }
  .slideshow .slideshow-slide .media-box {
    height: 100%;
    max-height: calc(28rem + 40vh);
    max-width: calc(100% - 2rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .firefox .slideshow .slideshow-slide .media-box {
    display: flex;
    justify-content: center;
    width: calc(100% - 2rem);
  }
  .slideshow .slideshow-slide .media-box img {
    width: auto !important;
    height: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .pointer {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(50%, 50%);
    z-index: 999;
    pointer-events: none;
  }
  .no-touch .size-desktop .pointer {
    display: block !important;
  }
  .pointer svg {
    display: block;
    height: 1.76rem;
    width: 1.76rem;
    transition: transform 0.1s cubic-bezier(0.1, 0.6, 0.4, 1);
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) rotate(-180deg);
    fill: #000;
  }
  .has-pointer .pointer svg {
    opacity: 1;
  }
  .has-pointer--reverse .pointer svg {
    transform: translate3d(-50%, -50%, 0);
  }
  body.has-pointer .slideshow-slide {
    cursor: none !important;
  }
}

.cookie-form {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffff00;
  padding: 1rem;
  z-index: 10;
  font-size: 1rem;
  font-weight: 100;
}

.cookie-form .btn {
  background: rgba(0, 0, 0, 0.8);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 100;
  margin: 1rem 0;
}

@media only screen and (min-width: 600px) {
  .cookie-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .cookie-form .btn {
    flex: 0 0 auto;
    width: auto;
    margin: 0;
    padding: 0;
    background: transparent;
  }
}

.lander {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  overflow: hidden;
}

.lander header {
  flex: 0 0 auto;
  background: #fff;
  color: #000;
}

@media only screen and (min-width: 600px) {
  .lander header h1 {
    text-align: center;
  }
  .lander header .btn {
    width: 25%;
    margin: 0;
  }
  .lander header .btn:last-child {
    text-align: right;
  }
}

.lander.lander--fullscreen {
  padding-bottom: 0;
}

.lander.lander--fullscreen header {
  background: transparent;
}

.lander__slider {
  display: block;
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  bottom: 5rem;
  transition: opacity 1.4s linear, transform 1.4s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.lander__slider.lander__slider--invisible {
  opacity: 0;
  transform: scale(0.9);
}

.has-categories .lander__slider {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
}

@media only screen and (orientation: portrait) {
  .lander__slider {
    top: calc(5rem + 5vh);
    bottom: calc(5rem + 5vh);
  }
}

.lander--fullscreen .lander__slider {
  top: 0;
  bottom: 0;
  z-index: -1;
}

.lander--fullscreen .lander__slider.lander__slider--invisible {
  transform: scale(1.1);
}

.lander__slider-element {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  background: #fff;
  transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
  pointer-events: none;
}

.lander__slider-element.is-active {
  opacity: 1;
  z-index: 1;
  pointer-events: all;
}

.lander__slider-element.is-active img {
  transform: scale(1.05);
}

.lander__slider-element.is-active-out {
  opacity: 0;
  z-index: 2;
}

.lander__slider-element.is-active-out img {
  transform: scale(1.1);
}

.lander__slider-element .media-box {
  height: 100%;
  max-height: calc(28rem + 40vh);
  max-width: calc(100% - 2rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.firefox .lander__slider-element .media-box {
  display: flex;
  justify-content: center;
  width: calc(100% - 2rem);
}

.lander__slider-element .media-box img {
  width: auto !important;
  height: 100%;
  transition: transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.lander--fullscreen .lander__slider-element .media-box {
  max-height: none;
  max-width: none;
}

.firefox .lander--fullscreen .lander__slider-element .media-box {
  width: 100%;
}

.lander--fullscreen .lander__slider-element .media-box.media-box--landscape {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: none;
}

.lander--fullscreen .lander__slider-element .media-box.media-box--landscape img {
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
}

.ie .lander--fullscreen .lander__slider-element .media-box.media-box--landscape img {
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}

@media only screen and (orientation: portrait) {
  .lander__slider-element .media-box {
    max-height: 100vw;
    max-width: calc(100% - 4rem);
  }
}

.menu {
  background: rgba(235, 235, 0, 0.97);
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 5rem;
  /*
    .has-menu.has-categories & {
        height: 100%;
    }

    .has-menu & {
        .menu-close {
            transform: rotate(0deg);
        }

        ul {
            bottom: 2rem;
            opacity: 1;
        }
    }

    .has-categories & {
        background: #000;
        opacity: 1;

        /*
        > .menu-title,
        > .menu-close {
            margin-top: -0.75rem;
        }
    }*/
}

.menu svg {
  fill: #000;
}

@supports (backdrop-filter: blur(5px)) {
  .menu {
    background: rgba(235, 235, 0, 0.6);
    backdrop-filter: blur(2rem);
  }
}

@media only screen and (min-width: 600px) {
  .menu {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    top: calc(5rem + 20vh);
  }
}

.menu > * {
  transition: margin 0.35s ease-in-out;
}

.menu ul {
  padding: 0;
  margin: 2rem 1rem;
  list-style: none;
  font-size: 1.6rem;
  font-weight: 100;
}

.menu ul li a {
  display: block;
  /*
                &:hover {
                    color: #ddd;
                }

                &:active {
                    color: #fff;
                }*/
}

.imprint,
.info {
  background: #ffff00;
  color: #000;
}

.imprint svg,
.info svg {
  fill: #000;
}

@supports (pointer-events: none) {
  .imprint:after,
  .info:after {
    content: '';
    position: sticky;
    display: block;
    width: 100%;
    bottom: 0;
    height: 6rem;
    pointer-events: none;
    background: linear-gradient(to top, rgba(255, 255, 0, 0.6), rgba(255, 255, 0, 0));
  }
  .imprint header > *,
  .info header > * {
    position: relative;
  }
  .imprint header:before,
  .info header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 0, 0.6), rgba(255, 255, 0, 0));
  }
}

.info-content {
  padding: 2rem 1rem;
}

@media only screen and (min-width: 600px) {
  .info-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.info-content__contact .media-box {
  margin: 2rem;
  background: #f5f500;
}

@media only screen and (min-width: 600px) {
  .info-content__contact {
    width: 33%;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
  }
  .info-content__contact .media-box {
    margin: 2rem 0;
    width: 90%;
  }
}

@media only screen and (min-width: 600px) {
  .info-content__info {
    width: 60%;
  }
}

.info-content__clients {
  margin-top: 4rem;
}

.btn[data-action="toggle-imprint"] {
  position: fixed;
  left: 100vw;
  transform: translateX(-50%);
  bottom: calc(5rem + 20vh);
  font-size: 1rem;
  z-index: 999;
  transition: left 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.btn[data-action="toggle-imprint"] span {
  transform: rotate(-90deg) translateY(-50%);
  display: block;
  transform-origin: 50% 50%;
  line-height: 2.5;
  white-space: nowrap;
}

.firefox .btn[data-action="toggle-imprint"] {
  transform: none;
  width: 3rem;
}

.firefox .btn[data-action="toggle-imprint"] span {
  transform: rotate(-180deg) translateY(0%);
  writing-mode: vertical-rl;
}

.has-albums .btn[data-action="toggle-imprint"],
.has-categories .btn[data-action="toggle-imprint"] {
  display: none;
}

.has-imprint .btn[data-action="toggle-imprint"] span,
.has-info .btn[data-action="toggle-imprint"] span {
  color: #000;
}

@media only screen and (min-width: 600px) {
  .btn[data-action="toggle-imprint"] {
    left: calc(100vw - 1rem);
  }
  .firefox .btn[data-action="toggle-imprint"] {
    left: calc(100vw - 3rem);
  }
  .btn[data-action="toggle-imprint"] span {
    line-height: 2;
  }
  @supports (--css: variable) {
    .has-info .btn[data-action="toggle-imprint"],
    .has-imprint .btn[data-action="toggle-imprint"] {
      left: calc(100vw - 1rem - var(--scrollbar-width));
    }
    .firefox .has-info .btn[data-action="toggle-imprint"], .firefox
    .has-imprint .btn[data-action="toggle-imprint"] {
      left: calc(100vw - 3rem - var(--scrollbar-width));
    }
  }
}

@media only screen and (min-width: 600px) {
  .imprint .info-content {
    max-width: 60%;
    display: block;
  }
}

/* categories */
.categories {
  background: #ffff00;
  color: #000;
}

.categories header {
  background: #ffff00;
}

.categories svg {
  fill: #000;
}

@media only screen and (min-width: 600px) {
  .categories header {
    transition: transform 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .categories header:before {
    content: "";
    background: #cccc00;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    transition: opacity 0.35s cubic-bezier(0.1, 0.9, 0.3, 1), visibility 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
  }
  .has-albums .categories header:before {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.35s cubic-bezier(0.1, 0.9, 0.3, 1);
  }
  .ios .categories header:before {
    display: none;
  }
  .has-albums .categories i {
    display: none;
  }
}

.category {
  display: none;
}

.category.category--opened {
  display: block;
}

.album-cover-wrapper {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 1rem;
}

.categories--masonry .album-cover-wrapper {
  margin: 2rem 2vw;
}

@supports (display: grid) and (--css: variable) {
  .categories--masonry .album-cover-wrapper {
    display: grid;
    grid-gap: 0 2vw;
    grid-template-columns: calc(50% - 2vw) calc(50% - 2vw);
    grid-auto-rows: 1vw;
  }
  .chrome .categories--masonry .album-cover-wrapper {
    grid-auto-rows: 6vw;
  }
}

@media only screen and (min-width: 600px) {
  .album-cover-wrapper {
    justify-content: flex-start;
    margin: 4rem 1rem;
  }
  .categories--masonry .album-cover-wrapper {
    margin: 2rem 0.3vw;
  }
  @supports (display: grid) and (--css: variable) {
    .categories--masonry .album-cover-wrapper {
      grid-gap: 0 0.3vw;
      grid-template-columns: calc(33.333333% - 0.2vw) calc(33.333333% - 0.2vw) calc(33.333333% - 0.2vw);
    }
    .chrome .categories--masonry .album-cover-wrapper {
      grid-auto-rows: 3vw;
    }
  }
}

.album-cover {
  width: calc(50% - 1vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}

.intersection-observer .album-cover .media-box-wrapper {
  transition: transform 0.5s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
  opacity: 0;
  transform: translate3d(0, 6rem, 0);
}

.intersection-observer .album-cover.album-cover--visible .media-box-wrapper {
  opacity: 1;
  transform: none;
}

.album-cover img {
  width: 100%;
  display: block;
}

.no-touch .album-cover:hover {
  z-index: 1;
}

.categories--masonry .album-cover {
  justify-content: flex-start;
  position: relative;
}

.intersection-observer .categories--masonry .album-cover .media-box-wrapper {
  transform: none;
  transition: opacity 0.75s cubic-bezier(0.1, 0.6, 0.4, 1);
}

@supports (display: grid) and (--css: variable) {
  .categories--masonry .album-cover {
    --grid-row-end-m: 50;
    grid-row-end: span calc(var(--grid-row-end-m) + 9);
    width: 100%;
    margin: 1vw 0;
  }
  .chrome .categories--masonry .album-cover {
    grid-row-end: span calc(var(--grid-row-end-m-chrome) + 1);
  }
  .categories--masonry .album-cover .media-box-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
  }
  .categories--masonry .album-cover .media-box {
    padding-bottom: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .categories--masonry .album-cover .media-box img {
    width: 100%;
    height: auto;
    position: relative;
  }
  @supports (object-fit: cover) {
    .categories--masonry .album-cover .media-box img {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .ie .categories--masonry .album-cover .media-box img {
      font-family: 'object-fit: cover; object-position: 50% 50%;';
    }
  }
}

@media only screen and (max-width: 599px) {
  .has-albums .album-cover {
    transition: none;
  }
}

@media only screen and (min-width: 600px) {
  .album-cover {
    width: calc(33.333% - 0.3vw);
    margin: 0 0.15vw;
  }
  .no-touch .categories--default .album-cover {
    position: relative;
    justify-content: center;
    margin-bottom: 0.3vw;
  }
  .no-touch .categories--default .album-cover .media-box {
    position: relative;
    overflow: visible;
    transition: transform 0.5s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .no-touch .categories--default .album-cover .media-box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: black;
    box-shadow: 0 0 1.5rem 1.5rem black;
    z-index: -1;
    transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
    opacity: 0;
  }
  .no-touch .categories--default .album-cover:hover .media-box {
    transform: scale(1.02) translate3d(0, -3%, 0);
    opacity: 0.75;
    transition: transform 0.5s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .no-touch .categories--default .album-cover:hover .media-box:before {
    opacity: 0.2;
  }
  .categories--masonry .album-cover {
    margin: 0.15vw 0.15vw 8rem;
  }
  @supports (display: grid) and (--css: variable) {
    .categories--masonry .album-cover {
      --grid-row-end-d: 30;
      grid-row-end: span calc(var(--grid-row-end-d) + 3);
      margin: 0.15vw 0;
    }
    .chrome .categories--masonry .album-cover {
      grid-row-end: span calc(var(--grid-row-end-d-chrome) + 1);
    }
    .no-touch .categories--masonry .album-cover {
      grid-row-end: span calc(var(--grid-row-end-d) - 1);
    }
    .no-touch.chrome .categories--masonry .album-cover {
      grid-row-end: span calc(var(--grid-row-end-d-chrome) - 1);
    }
  }
}

.album-title {
  margin-top: 0.5rem;
  min-height: 8vw;
  transition: transform 0.5s cubic-bezier(0.1, 0.6, 0.4, 1) 0.25s, opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1) 0.25s;
  overflow: hidden;
}

.intersection-observer .album-title {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
}

.album-cover--visible .album-title {
  opacity: 1;
  transform: none;
}

.intersection-observer .categories--masonry .album-title {
  transform: none;
}

@supports (display: grid) and (--css: variable) {
  .categories--masonry .album-title {
    min-height: 0;
    background: #ffff00;
    padding-bottom: 0.5rem;
    flex: 0 0 auto;
  }
  .no-touch .categories--masonry .album-title {
    background: transparent;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 600px) {
  .categories--default .album-title {
    margin: 1rem 0;
    min-height: 0.3vw;
  }
  @supports (display: grid) and (--css: variable) {
    .categories--masonry .album-title {
      padding-bottom: 1.5rem;
    }
    .no-touch .categories--masonry .album-title {
      padding-bottom: 0;
    }
  }
  .no-touch .album-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.6rem;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0;
    padding: 1rem;
    margin: 0;
    text-align: center;
    transition: left 0.5s cubic-bezier(0.1, 0.6, 0.4, 1), top 0.5s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
    pointer-events: none;
    opacity: 0;
  }
  .no-touch .active-cover .album-title {
    opacity: 1;
  }
  @supports (--css: variable) {
    .no-touch .active-cover .album-title {
      --offset-top: 0;
      --offset-left: 0;
      top: var(--offset-top);
      left: var(--offset-left);
    }
  }
}

/* albums */
@media only screen and (min-width: 600px) {
  .albums {
    background: #ffff00;
    overflow: hidden;
  }
}

.album-wrapper {
  display: none;
}

.album-wrapper.album-wrapper--opend {
  display: block;
}

@media only screen and (max-width: 599px) {
  .album-wrapper.album-wrapper--opend {
    min-height: calc(100vh + 1px);
  }
}

.album {
  color: #000;
}

.album svg {
  fill: #000;
}

.album .album-header {
  cursor: pointer;
  height: 5rem;
  margin-bottom: 5rem;
}

@media only screen and (min-width: 600px) {
  .album .album-header {
    margin-bottom: 0;
  }
}

.album .album-content {
  position: relative;
  margin-top: -10rem;
  margin-bottom: -10rem;
  min-height: 20rem;
}

.album .album-footer {
  display: flex;
  margin-top: 5rem;
  transition: opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.album .album-footer > * {
  position: relative;
}

.album .album-footer:before {
  content: "";
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 7.5rem;
  display: block;
  background: linear-gradient(to top, rgba(255, 255, 0, 0.8) 50%, rgba(255, 255, 0, 0));
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
  visibility: hidden;
}

@supports (pointer-events: none) {
  .album .album-footer:before {
    visibility: visible;
    pointer-events: none;
  }
}

@media only screen and (min-width: 600px) {
  .album .album-footer {
    margin-top: 0;
    align-items: center;
    opacity: 0;
    pointer-events: none;
  }
}

.album.album--focused-post .album-info-container,
.album.album--focused-post .album-index-container, .album.album--focused-pre .album-info-container,
.album.album--focused-pre .album-index-container {
  display: none;
}

@media only screen and (min-width: 600px) {
  .album .album-content--loaded ~ .album-footer {
    opacity: 1;
    pointer-events: all;
  }
}

.album .album-content--loading ~ .album-footer,
.album .album-content--loading ~ .album-header {
  display: none;
}

.album .album-content--loading .album-index-container,
.album .album-content--loading .album-info-container,
.album .album-content--loading .album-images,
.album .album-content--loading .album-footer {
  display: none;
}

.album .album-images .media-box {
  opacity: 0.15;
  transition: opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.album .album-images .media-box img {
  display: block;
  width: 100%;
  min-height: 1px;
  /*has to have at least 1px */
}

.album .album-images .media-box.active {
  opacity: 1;
}

.has-modals .album .album-images .media-box.active {
  opacity: 0.35;
}

.album .album-images .media-box.post-active {
  opacity: 0.9;
}

.has-modals .album .album-images .media-box.post-active {
  opacity: 0.35;
}

@media only screen and (max-width: 599px) {
  .album .album-images .media-box {
    background: #121211;
  }
}

@media only screen and (min-width: 600px) {
  .album .album-images .media-box {
    opacity: 1;
    background: none;
  }
}

@media only screen and (max-width: 599px) {
  .album {
    width: 100%;
    background: #ffff00;
    visibility: hidden;
  }
  .has-albums .album {
    visibility: visible;
    transition: visibility 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.35s;
  }
  .album .album-header {
    transition: transform 0.75s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1) 0.15s;
  }
  .album .album-content {
    transition: transform 0.75s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .album .album-header:after,
  .album [data-action="open-detail-info"]:after,
  .album [data-action="open-detail-index"]:after {
    content: "";
    height: 5rem;
    width: 50vw;
    position: fixed;
    bottom: 0;
    left: 0;
    visibility: hidden;
  }
  .album [data-action="open-detail-index"]:after {
    right: 0;
    left: auto;
  }
  .album .album-header:after {
    width: auto;
    top: 0;
    right: 0;
  }
  .album.album--focused-post header,
  .album.album--focused-post footer {
    opacity: 0;
    pointer-events: none;
  }
  .album.album--focused {
    z-index: 1;
    transition: none;
  }
  .album.album--focused header,
  .album.album--focused footer {
    z-index: 4;
  }
  .album.album--focused:not(.has-album-index-opened):not(.has-album-info-opened) header > *,
  .album.album--focused:not(.has-album-index-opened):not(.has-album-info-opened) footer > * {
    transition: opacity 0.75s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .no-safari .is-scrolled:not(.is-scrolling) .album.album--focused:not(.has-album-index-opened):not(.has-album-info-opened) header > *, .no-safari .is-scrolled:not(.is-scrolling)
  .album.album--focused:not(.has-album-index-opened):not(.has-album-info-opened) footer > * {
    transition: opacity 0.75s cubic-bezier(0.1, 0.6, 0.4, 1) 4s;
    opacity: 0;
  }
  .album.album--focused .album-header:after,
  .album.album--focused [data-action="open-detail-info"]:after,
  .album.album--focused [data-action="open-detail-index"]:after {
    visibility: visible;
  }
  .album.album--focused-pre {
    transition: none;
  }
  .album:not([data-loaded="true"]) .album-footer,
  .album:not([data-loaded="true"]) .album-header,
  .album:not([data-loaded="true"]) .album-content,
  body:not(.has-albums) .album .album-footer,
  body:not(.has-albums) .album .album-header,
  body:not(.has-albums) .album .album-content {
    transition: none;
  }
  .album.album--focused + * + * + .album.album--focused-pre > *,
  .album.album--focused + * + .album.album--focused-pre > *,
  .album.album--focused + .album.album--focused-pre > * {
    transform: translate3d(0, 50vh, 0);
  }
  .album.has-album-info-opened .album-content, .album.has-album-index-opened .album-content {
    margin-bottom: 0;
  }
  .album.has-album-info-opened .album-footer, .album.has-album-index-opened .album-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .album.has-album-info-opened .album-footer:before, .album.has-album-index-opened .album-footer:before {
    opacity: 1;
  }
  .album:last-child {
    margin-bottom: 40vh;
  }
}

@media only screen and (min-width: 600px) {
  .album {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform-origin: center center;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
  }
  .album .album-header,
  .album .album-footer {
    position: relative;
    top: auto;
    bottom: auto;
    flex: 0 0 auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .album .album-content {
    flex: 1 0 auto;
    margin: 0;
    position: relative;
    z-index: -1;
  }
  .album .album-images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .album.album--fade-in .media-box {
    opacity: 0 !important;
  }
  .has-albums .album {
    transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.25s cubic-bezier(0.1, 0.9, 0.3, 1);
  }
  .has-albums .album > * {
    transition: none;
  }
  .has-albums .album .album-images {
    transition: transform 0.85s cubic-bezier(0.1, 0.9, 0.3, 1);
  }
  .has-albums .album .album-footer {
    transition: transform 0.4s cubic-bezier(0.1, 0.9, 0.3, 1);
  }
  .has-albums .album .btn--layer-toggle {
    opacity: 0;
    visibility: hidden;
  }
  .has-albums .album.album--focused {
    opacity: 1;
    pointer-events: all;
    transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.25s cubic-bezier(0.1, 0.9, 0.3, 1);
  }
  .has-albums .album.album--focused .btn--layer-toggle {
    transition: opacity 0.5s cubic-bezier(0.1, 0.9, 0.3, 1) 0.5s;
    opacity: 1;
    visibility: visible;
  }
  .has-albums .album.album--focused-post {
    transform: translate3d(-10vw, 0, 0);
  }
  .has-albums .album.album--focused-post .album-images {
    transform: translate3d(-90vw, 0, 0);
  }
  .has-albums .album.album--focused-post .album-footer {
    transform: translate3d(-10vw, 0, 0);
  }
  .has-albums .album.album--focused-pre {
    transform: translate3d(10vw, 0, 0);
    visibility: hidden;
    transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1), opacity 0.25s cubic-bezier(0.1, 0.9, 0.3, 1), visibility 0s linear 0.25s;
  }
  .has-albums .album.album--focused-pre .album-images {
    transform: translate3d(90vw, 0, 0);
  }
  .has-albums .album.album--focused-pre .album-footer {
    transform: translate3d(10vw, 0, 0);
  }
  body:not(.has-albums) .album {
    transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1) 0.25s;
  }
}

.album-content-loading-spinner {
  height: 50vh;
  transition: opacity 0.75s cubic-bezier(0.1, 0.6, 0.4, 1), visibility 0s linear 0.75s;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.album-content--loaded .album-content-loading-spinner {
  opacity: 0;
  visibility: hidden;
  position: fixed;
}

@media only screen and (min-width: 600px) {
  .album-content-loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 98vh;
  }
  .ios .album-content-loading-spinner {
    height: 90vh;
  }
}

.album-page,
.album-info {
  cursor: pointer;
}

.album-info {
  flex: 1;
}

.album-info span {
  display: none;
  font-family: "GT-America-Expanded-Medium", Helvetica, Arial, sans-serif;
}

.has-album-info-opened .album-info span {
  display: inline;
}

.album-page {
  flex: 1;
  text-align: right;
}

.has-album-index-opened .album-page span:not(.album-page-close) {
  display: none;
}

.album-page-i,
.album-page-n {
  padding: 0 0.125em;
}

.album-page-close {
  display: none;
  font-family: "GT-America-Expanded-Medium", Helvetica, Arial, sans-serif;
}

.has-album-index-opened .album-page-close {
  display: inline;
}

@media only screen and (max-width: 599px) {
  .has-album-index-opened [data-action="open-detail-info"] {
    opacity: 0;
    pointer-events: none;
  }
  .has-album-info-opened [data-action="open-detail-index"] {
    opacity: 0;
    pointer-events: none;
  }
}

.album-nav {
  display: flex;
  justify-content: center;
  flex: 2;
  padding-right: 33%;
  user-select: none;
}

.album-nav .btn {
  white-space: nowrap;
  display: flex;
  flex: 1;
  padding: 0 1rem;
  transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1), transform 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.album-nav .btn:nth-child(0) {
  transition-delay: 0s;
}

.album-nav .btn:nth-child(1) {
  transition-delay: 0.02s;
}

.album-nav .btn:nth-child(2) {
  transition-delay: 0.04s;
}

.album-nav .btn:nth-child(3) {
  transition-delay: 0.06s;
}

.has-album-info-opened .album-nav .btn,
.has-album-index-opened .album-nav .btn {
  opacity: 0;
  pointer-events: none;
}

.has-album-info-opened .album-nav .btn {
  transform: translate3d(2rem, 0, 0);
}

.has-album-index-opened .album-nav .btn {
  transform: translate3d(-2rem, 0, 0);
}

.album-nav .album-nav-btn-prev-album,
.album-nav .album-nav-btn-prev {
  justify-content: flex-end;
}

.album--last-element .album-nav .album-nav-btn-next-album, .album--last-element
.album-nav .album-nav-btn-next {
  display: none;
}

.album--first-element .album-nav .album-nav-btn-prev {
  display: none;
}

.album-nav .album-nav-btn-prev-album,
.album-nav .album-nav-btn-next-album {
  display: none;
}

.album-nav .album-nav-btn-prev-album span span,
.album-nav .album-nav-btn-next-album span span {
  display: none;
}

.album-nav .album-nav-btn-prev-album span span,
.album-nav .album-nav-btn-next-album span span {
  display: none;
  /*
                white-space: nowrap;
                position: absolute;
                top: 0;
                right: -0.6rem;
                transform: translateX(100%);
                width: 20vw;
                display: inline-block;
                @include fader(right, $back-color--albums, 4rem);
                overflow: hidden;
                display: none;
                */
}

.album--last-element .album-nav .album-nav-btn-next-album {
  display: flex;
}

.album--first-element .album-nav .album-nav-btn-prev-album {
  display: flex;
}

.album-index-container,
.album-info-container {
  overflow: hidden;
  position: fixed;
  padding: 0;
  top: 5rem;
  bottom: 0;
  width: 100%;
  background-color: #ffff0a;
  transform: translateY(100%);
  visibility: hidden;
  transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1), visibility 0s cubic-bezier(0.1, 0.9, 0.3, 1) 0.25s;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  mask-image: linear-gradient(to bottom, transparent 0%, black 5rem);
}

@supports (backdrop-filter: blur(5px)) {
  .album-index-container,
  .album-info-container {
    background: rgba(235, 235, 0, 0.6);
    backdrop-filter: blur(2rem);
  }
}

.album-index-container.active,
.album-info-container.active {
  transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
  opacity: 1;
}

.album-has-info .album-index-container, .album-has-info
.album-info-container {
  height: auto;
}

@media only screen and (max-width: 599px) {
  .album-index-container,
  .album-info-container {
    z-index: 3;
  }
}

@media only screen and (min-width: 600px) {
  .album-index-container,
  .album-info-container {
    width: 50%;
    top: 0;
    min-width: 50rem;
    max-width: 100vw;
    height: 100%;
    transform: translateX(-100%);
  }
}

.album-info-container {
  left: 0;
  right: auto;
}

.album-info-container * + h2 {
  margin-top: 2rem;
}

.album-info-container h3 {
  font-size: 1.25rem;
  font-weight: 100;
}

.album-info-container > *:first-child {
  margin-top: 5rem;
}

.album-info-container > *:last-child {
  padding-bottom: 7rem;
}

.album-info-container h3, .album-info-container h2, .album-info-container p {
  padding: 0 1rem;
}

.has-album-info-opened .album-info-container {
  transform: none;
  visibility: visible;
  transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1);
}

.album-index-container {
  right: 0;
  left: auto;
}

.has-album-index-opened .album-index-container {
  transform: none;
  visibility: visible;
  transition: transform 0.25s cubic-bezier(0.1, 0.9, 0.3, 1);
}

@media only screen and (min-width: 600px) {
  .album-index-container {
    transform: translateX(100%);
  }
}

.album-index-container-container {
  flex-wrap: wrap;
  display: flex;
  max-width: 100vw;
  padding: 4rem;
}

.album-index-container-container a {
  width: 50%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem 2rem;
  align-items: center;
}

.album-index-container-container a .media-box img {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .album-index-container-container {
    padding: 8rem 4rem;
  }
  .album-index-container-container a {
    width: 33%;
  }
}

/* typography ------------------------------------------------------------- */
body {
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

.menu ul li a,
header h2,
header h3,
button,
input,
select,
textarea {
  font-family: "GT-America-Expanded-Medium-Italic", Helvetica, Arial, sans-serif;
}

/* button ------------------------------------------------------------- */
.btn {
  text-transform: uppercase;
}

/* lander ------------------------------------------------------------- */
.lander header h1 {
  text-transform: uppercase;
}

/* menu ------------------------------------------------------------- */
.menu {
  background: rgba(255, 255, 0, 0.8);
}

@supports (backdrop-filter: blur(5px)) {
  .menu {
    background: rgba(255, 255, 0, 0.8);
    backdrop-filter: blur(1rem);
  }
}

.menu small {
  display: none;
}

/* categories ------------------------------------------------------------- */
/* albums  ------------------------------------------------------------- */
@media only screen and (max-width: 599px) {
  .album .album-images .media-box {
    background: transparent;
  }
}
