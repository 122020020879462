/// Breakpoint Definitions
/// @group Breakpoints
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

// TODO set this as your heart desires
$bp--small: 599px;
$bp--large: 600px;
$bp--x-large: 1200px;

/// Mixin for breakpoints
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin breakpoint($name) {
    @if $name == "mobile" or $name == "m" { // Mobile
        @media only screen and (max-width: $bp--small) {
            @content;
        }
    } @else if $name == "desktop" or $name == "d" { // m--xl // Tablet to Large Desktop --> used for everything not one-row-mobile
        @media only screen and (min-width: $bp--large) {
            @content;
        }
    } @else if $name == "big-desktop" or $name == "bd" { // m--xl // Tablet to Large Desktop --> used for everything not one-row-mobile
        @media only screen and (min-width: $bp--x-large) {
            @content;
        }
    }
}

/// Alias for the breakpoint mixin
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin respond-to($name) {
    @include breakpoint($name) {
        @content;
    }
}
