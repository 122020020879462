.btn[data-action="toggle-imprint"] {
    position: fixed;
    left: 100vw;
    transform: translateX(-50%);
    bottom: calc(#{$header-height} + 20vh);
    font-size: $font-size--c;
    z-index: 999;

    transition: left $trans-time--s $trans-ease--default;

    span { // nesting needed for writing-mode to work in chrome
        // writing-mode: vertical-rl;
        transform: rotate(-90deg) translateY(-50%);
        display: block;
        transform-origin: 50% 50%;
        line-height: 2.5;
        white-space: nowrap;
    }

    .firefox & {
        transform: none;
        width: 3rem;
        span {
            transform: rotate(-180deg) translateY(0%);
            writing-mode: vertical-rl;
        }
    }

    .has-albums &,
    .has-categories & {
        display: none;
    }

    .has-imprint &,
    .has-info & {
        .no-mac & {
            // display: none; // dont show this button on windows if opened so it dows not jump
        }

        span {
            color: $text-color--info;
        }
    }

    @include respond-to(desktop) {
        left: calc(100vw - #{$padding-h});
        .firefox & {
            left: calc(100vw - 3rem);
        }

        span {
            line-height: 2;
        }

        // take scrollbar into account if custom properties are supported
        @supports (--css: variable) {
            .has-info &,
            .has-imprint & {
                left: calc(100vw - #{$padding-h} - var(--scrollbar-width));
                .firefox & {
                    left: calc(100vw - 3rem - var(--scrollbar-width));
                }
            }
        }
    }
}

.imprint .info-content {
    @include respond-to(desktop) {
        max-width: 60%;
        display: block;
    }
}
