// custom layer styles for templates
// only edit in template branches

/* lander ------------------------------------------------------------- */
.lander header h1 {
    text-transform: uppercase;
}

/* menu ------------------------------------------------------------- */
.menu {
    background: rgba($back-color--menu, 0.8);

    @supports (backdrop-filter: blur(5px)) {
       background: rgba($back-color--menu, 0.8);
       backdrop-filter: blur(1rem);
    }

    small {
        // Kann man die Zahlen hinter den Kategorien weg nehmen?
        // Wenn man in der Kategorie drin ist finde ich das super aber im Startmenü wenns geht wegnehmen
        display: none; // <- hide the numbers
    }
}

/* categories ------------------------------------------------------------- */
.album-title {
    @include respond-to(desktop) {
        .no-touch & {
            // color: $back-color--secondary;
        }
    }
}

/* albums  ------------------------------------------------------------- */

.album .album-images .media-box {
    @include respond-to(mobile) {
        background: transparent;
    }
}
