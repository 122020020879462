/// Mixin for fluid typography
///
/// @group humans-machines
/// @param {list} $properties - The properties to apply the calculation e.g. font-size, padding, ...
/// @param {number} $min-vw - The min viewport width in pixel
/// @param {number} $max-vw - The max viewport width in pixel
/// @param {number} $min-value - The min value to apply
/// @param {number} $max-value - The max value to apply
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@mixin fader($side: top, $color: $back-color--default, $size: $padding-v) {
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;

        @if $side == 'top' {
            background: linear-gradient(to bottom, $color, rgba($color, 0));
            left: 0;
            right: 0;
            bottom: 1px;
            height: $size;
            transform: translate3d(0, 100%, 0);
        } @elseif $side == 'right' {
            background: linear-gradient(to left, $color, rgba($color, 0));
            top: 0;
            bottom: 0;
            right: -1px;
            width: $size;
        } @else {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            top: 1px;
            height: $size;
            transform: translate3d(0, -100%, 0);
        }
    }
}

@mixin fader-color($side: top, $color: $back-color--default) {
    &:after {
        @if $side == 'top' {
            background: linear-gradient(to bottom, $color, rgba($color, 0));
        } @else {
            background: linear-gradient(to top, $color, rgba($color, 0));
        }
    }
}

/// Shorthand Method for keyframes
/// Needed here since mixins need animations and therefore come next in mains.scss
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name for the keyframes
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

/// Shorthand Method for tackling big safari browsers
//  since it has some problems processing some effects
//  not needed yet
//  @mixin big-safari() {
//      @media only screen and (min-width: 1600px) and (min-device-pixel-ratio: 2),
//      only screen and (min-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) {
//          .safari &,
//          .safari {
//              @content;
//          }
//      }
//  }

