// custom layout styles for templates
// only edit in template branches

/* typography ------------------------------------------------------------- */

body {
    text-transform: uppercase;
    letter-spacing: 0.01em;
}

.menu ul li a,
header h2,
header h3,
button,
input,
select,
textarea {
    font-family: $font-family--secondary;
}
