@include respond-to(desktop) {
    .pointer {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(50%, 50%);
        z-index: 999;
        pointer-events: none;

        // show pointer on desktop touch only
        .no-touch .size-desktop & {
            display: block !important;
        }

        svg {
            display: block;
            height: $font-size--a * 1.1; // full line height and a little more
            width: $font-size--a * 1.1; // full line height ans a little more
            transition: transform 0.1s $trans-ease--default;
            opacity: 0;
            transform: translate3d(-50%, -50%, 0) rotate(-180deg);
            fill: $text-color--albums;

            .has-pointer & {
                opacity: 1;
            }

            .has-pointer--reverse & {
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }

    body.has-pointer .slideshow-slide {
        cursor: none !important;
    }
}
