// slideshow is desktop only
@include respond-to(desktop) {
    .slideshow {
        // enable flickity slideshow
        &:after {
            content: 'flickity';
            display: none; /* hide :after */
        }

        // dont show slideshow if its not enabled
        opacity: 0;

        &.flickity-enabled {
            opacity: 1;
        }

        .slideshow-slide {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            overflow: hidden;

            .media-box {
                height: 100%;
                max-height: calc(28rem + 40vh); // only scale slightly on bigger screens
                max-width: calc(100% - #{$padding-h * 2});
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);

                .firefox & {
                    display: flex; // fix for firefox
                    justify-content: center;
                    width: calc(100% - #{$padding-h * 2});
                }

                img {
                    width: auto !important;
                    height: 100%;
                }
            }
        }
    }
}
