/* categories */

// Reduce chrome-bug by extending grid rows where grid-rows have a max value
// https://bugs.chromium.org/p/chromium/issues/detail?id=688640
$chrome-grid-extend: 6;
$chrome-grid-extend-d: $chrome-grid-extend / 2;

// layer wrapper for all categories
.categories {
    background: $back-color--categories;
    color: $text-color--categories;

    header {
        background: $back-color--categories;

        @if $fading-header == true {
            @include fader(top, $back-color--categories);
        }
    }

    svg {
        fill: $text-color--categories;
    }

    @include respond-to(desktop) {
        header {
            transition: transform $trans-time--s $trans-ease--default;

            // fade to dark while transitioning to albums
            &:before {
                content: "";
                background: darken($back-color--categories, 10);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100vh;
                transition: opacity $trans-time--layer $trans-ease--layer,
                            visibility 0s $trans-ease--layer $trans-time--layer;
                z-index: 3;
                opacity: 0;
                visibility: hidden;

                .has-albums & {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity $trans-time--layer $trans-ease--layer;
                }

                .ios & {
                    display: none;
                }
            }
        }

        i {
            .has-albums & {
                display: none;
            }
        }
    }
}

.category {
    display: none;

    &.category--opened {
        display: block;
    }
}

.album-cover-wrapper {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: $padding-v $padding-h;

    // masonry style
    .categories--masonry & {
        margin: $padding-v $grid-gap-mobile-h;

        @supports (display: grid) and (--css: variable) {
            display: grid;
            grid-gap: 0 $grid-gap-mobile-h;
            grid-template-columns: calc(50% - #{$grid-gap-mobile-h * 1}) calc(50% - #{$grid-gap-mobile-h * 1}); // two rows minus the margin
            grid-auto-rows: 1vw;

            // Reduce chrome-bug
            .chrome & {
                grid-auto-rows: 1vw * $chrome-grid-extend;
            }
        }
    }

    @include respond-to(desktop) {
        justify-content: flex-start;
        margin: $padding-v * 2 $padding-h;

        .categories--masonry & {
            margin: $padding-v $grid-gap-desktop-h;

            @supports (display: grid) and (--css: variable) {
                grid-gap: 0 $grid-gap-desktop-h;
                grid-template-columns: calc(33.333333% - #{$grid-gap-desktop-h * 0.66666})
                                       calc(33.333333% - #{$grid-gap-desktop-h * 0.66666})
                                       calc(33.333333% - #{$grid-gap-desktop-h * 0.66666}); // three rows minus the margin

                // Reduce chrome-bug
                .chrome & {
                    grid-auto-rows: 1vw * $chrome-grid-extend-d;
                }
            }
        }
    }
}

.album-cover {
    @if $grid-gap-mobile-h == 0 {
        width: 50%;
    } @else {
        width: calc(50% - #{$grid-gap-mobile-h / 2});
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;

    // fade in album cover as entering viewport for native intersection observer browsers
    .intersection-observer & {
        .media-box-wrapper {
            transition: transform $trans-time--m $trans-ease--default,
                        opacity $trans-time--xl $trans-ease--default;
            opacity: 0;
            transform: translate3d(0, 6rem, 0);
        }

        &.album-cover--visible {
            .media-box-wrapper {
                opacity: 1;
                transform: none;
            }
        }
    }

    img {
        width: 100%;
        display: block;
    }

    // show floating title in top of other images
    .no-touch & {
        &:hover {
            z-index: 1;
        }
    }

    // masonry style
    .categories--masonry & {
        justify-content: flex-start;
        position: relative;

        .intersection-observer & {
            .media-box-wrapper {
                transform: none;
                transition: opacity $trans-time--l $trans-ease--default;
            }
        }

        @supports (display: grid) and (--css: variable) {
            --grid-row-end-m: 50;
            grid-row-end: span calc(var(--grid-row-end-m) + 9); // + 9 compensates for one title line below
            width: 100%;
            margin: $grid-gap-mobile-h / 2 0;

            // Reduce chrome-bug
            .chrome & {
                grid-row-end: span calc(var(--grid-row-end-m-chrome) + #{floor(9 / $chrome-grid-extend)});
            }

            .media-box-wrapper {
                flex: 1 1 auto;
                overflow: hidden;
                position: relative;
            }

            .media-box {
                padding-bottom: 0 !important;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                img {
                    width: 100%;
                    height: auto; // kind of a stunt – but our legacy browser approach
                    position: relative;

                    // object-fit cover seems stressfull for safari
                    @supports (object-fit: cover) {
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        object-position: 50% 50%;

                        .ie & {
                            font-family: 'object-fit: cover; object-position: 50% 50%;'; // ie fix probably
                        }
                    }
                }
            }
        }
    }

    @include respond-to(mobile) {
        &:nth-child(2n) {
            // transform: translate(0, 5rem);
        }

        // dont animate if albums are opened
        .has-albums & {
            transition: none;
        }
    }

    @include respond-to(desktop) {
        @if $grid-gap-desktop-h == 0 {
            width: 33.333%;
            margin: 0;
        } @else {
            width: calc(33.333% - #{$grid-gap-desktop-h});
            margin: 0 $grid-gap-desktop-h / 2;
        }

        // show zoom effect for default gallery
        .no-touch .categories--default & {
            position: relative;
            justify-content: center;
            margin-bottom: $grid-gap-desktop-v;

            .media-box {
                position: relative;
                overflow: visible;
                transition: transform $trans-time--m $trans-ease--default,
                            opacity $trans-time--m $trans-ease--default;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: black;
                    box-shadow: 0 0 1.5rem 1.5rem rgba(0, 0, 0, 1);
                    z-index: -1;
                    transition: opacity $trans-time--s $trans-ease--default;
                    opacity: 0;
                }
            }

            &:hover {
                .media-box {
                    // transform: scale(1.02) translate3d(0, -5%, 0);
                    transform: scale(1.02) translate3d(0, -3%, 0);
                    opacity: 0.75;
                    transition: transform $trans-time--m $trans-ease--default,
                    opacity $trans-time--m $trans-ease--default;

                    &:before {
                        opacity: 0.2;
                    }
                }
            }
        }

        // masonry style
        .categories--masonry & {
            margin: $grid-gap-desktop-h / 2 $grid-gap-desktop-h / 2 8rem;

            @supports (display: grid) and (--css: variable) {
                --grid-row-end-d: 30;
                grid-row-end: span calc(var(--grid-row-end-d) + 3); // + 3 compensates for one title line below
                margin: $grid-gap-desktop-h / 2 0;

                // Reduce chrome-bug
                .chrome & {
                    grid-row-end: span calc(var(--grid-row-end-d-chrome) + #{floor(3 / $chrome-grid-extend-d)});
                }

                // Remove title line compensation
                .no-touch & {
                    grid-row-end: span calc(var(--grid-row-end-d) - #{floor($grid-gap-desktop-raw / 2 + 1)}); // remove compensate
                }

                // Reduce chrome-bug
                .no-touch.chrome & {
                    grid-row-end: span calc(var(--grid-row-end-d-chrome) - #{floor($grid-gap-desktop-raw / 2 / $chrome-grid-extend-d + 1)});
                }
            }
        }
    }
}

.album-title {
    margin-top: 0.5rem;
    min-height: $grid-gap-mobile-v;
    transition: transform $trans-time--m $trans-ease--default $trans-time--s,
                opacity $trans-time--m $trans-ease--default $trans-time--s;
    overflow: hidden; // fix: to long names do funny space at the right side

    .intersection-observer & {
        opacity: 0;
        transform: translate3d(0, 2rem, 0);
    }

    .album-cover--visible & {
        opacity: 1;
        transform: none;
    }

    .categories--masonry & {
        .intersection-observer & {
            transform: none;
        }

        @supports (display: grid) and (--css: variable) {
            min-height: 0;
            background: $back-color--categories;
            padding-bottom: 0.5rem;
            flex: 0 0 auto;

            .no-touch & {
                background: transparent;
                padding-bottom: 0;
            }
        }
    }

    @include respond-to(desktop) {

        // default style
        .categories--default & {
            margin: 1rem 0;
            min-height: $grid-gap-desktop-v;
        }

        // masonry style
        .categories--masonry & {
            @supports (display: grid) and (--css: variable) {
                padding-bottom: 1.5rem;

                .no-touch & {
                    padding-bottom: 0;
                }
            }
        }

        // mouse move effect
        .no-touch & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include textstyle--a;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 0;
            padding: 1rem;
            margin: 0;
            text-align: center;
            transition: left $trans-time--m $trans-ease--default,
                        top $trans-time--m $trans-ease--default,
                        opacity $trans-time--m $trans-ease--default;
            pointer-events: none;
            opacity: 0;
        }

        // show album title on rollover
        .no-touch .active-cover & {
            opacity: 1;

            @supports (--css: variable) {
                --offset-top: 0;
                --offset-left: 0;
                top: var(--offset-top);
                left: var(--offset-left);
            }
        }
    }
}

